<template>
  <v-form
    :style="{ width: '100%', maxWidth: '300px' }"
    class="mr-1"
    @submit.prevent="searchStarted ? nextSearch() : searchNow()"
  >
    <v-text-field
      v-model="searchOld"
      label="Search"
      :loading="searchLoading"
      class="ml-1"
      color="white"
      dense
      height="100%"
      hide-details
      solo
    >
      <template v-slot:prepend-inner v-if="searchStarted">
        <v-btn icon class="pa-1" @click.native.prevent.stop="prevSearch">
          <v-icon small>fas fa-chevron-left</v-icon>
        </v-btn>
        <v-btn icon class="pa-1" @click.native.prevent.stop="nextSearch">
          <v-icon small>fas fa-chevron-right</v-icon>
        </v-btn>
      </template>
      <template v-slot:append>
        <v-btn
          v-if="searchStarted || search"
          icon
          @click.native.prevent.stop="clear"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
        <v-btn
          icon
          @click.native.prevent.stop="searchNow()"
          :loading="searchLoading"
        >
          <v-icon>fas fa-search</v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import {
  customFilterArr,
  GetOnlyOpened,
  searchInObject,
  searchInObjects
} from "@/functions";

export default {
  name: "search_report",
  data: () => ({
    searchLoading: false,
    start: 0,
    searchOld: "",
    count: 50,
    lastSearchIndex: 0,
    searchResults: []
  }),
  computed: {
    search: {
      get() {
        return this.searchObj.text;
      },
      set(val) {
        this.$store.commit("searchText", val);
      }
    },
    searchStarted: {
      get() {
        return this.searchObj.started;
      },
      set(val) {
        this.$store.commit("searchStarted", val);
      }
    },
    reportType() {
      return this.report.type;
    },
    ...mapGetters({
      searchObj: "search",
      report: "report"
    })
  },
  beforeDestroy() {
    this.searchStarted = false;
  },
  methods: {
    associations() {
      if (this.reportType === 0) {
        return this.reports;
      } else if (this.reportType === 1 || this.reportType === 2) {
        return GetOnlyOpened(this.reports);
      } else {
        return customFilterArr(this.reports, "Client_Associations");
      }
    },
    clear() {
      this.search = null;
      this.finish();
      this.$emit("remove");
    },
    subtractArrays(arr1, arr2, key) {
      const eq = (p, q) => p[key] === q[key];
      return arr1.filter(p => arr2.every(q => !eq(p, q)));
    },
    removeDublicates(arr) {
      let newArr = [];
      arr.forEach(el => {
        if (newArr.some(r => r.id === el.id)) return true;
        else {
          newArr.push(el);
        }
      });
      return newArr;
    },
    mark() {
      this.$emit("mark");
    },
    removeMark() {
      this.$emit("remove");
    },
    finish() {
      this.searchStarted = false;
      this.searchLoading = false;
      this.searchResults = [];
      this.lastSearchIndex = 0;
      this.start = 0;
    },
    async prevSearch() {
      await this.removeMark();
      this.lastSearchIndex =
        this.lastSearchIndex <= 0 ? 0 : this.lastSearchIndex - 1;
      this.$store.commit(
        "reportIndex",
        this.getIndex(this.searchResults[this.lastSearchIndex])
      );
      await this.mark();
    },
    async searchNow() {
      this.search = this.searchOld;
      if (!this.search) {
        await this.removeMark();
        this.searchStarted = false;
        this.searchLoading = false;
        this.search = null;
        return;
      }
      if (!this.searchStarted) {
        this.finish();
      }
      this.searchStarted = true;
      this.searchLoading = true;
      let search = this.search.trim();
      await this.removeMark();
      let objects = [],
        result = null,
        keys = [],
        rest = [],
        // allPayments = this.GetPayments(),
        associations = new Array(...this.associations());
      associations = associations.splice(this.start, this.count);
      if (this.report.type < 3) {
        objects = associations;
        keys = [
          "Client_Associations",
          "Collection Attorney",
          "Management Company",
          "Pre-Litigation Paralegal",
          "General Attorney",
          "Litigation Paralegal",
          "Home Owner",
          "Client Address",
          "Matter Reference",
          "Matter Number",
          "Status"
        ];
        result = searchInObjects(objects, search.toLowerCase(), keys);
        result = this.removeDublicates(result);
        rest = this.subtractArrays(objects, result, "id");
      } else {
        keys = [
          "Client_Associations",
          "Collection Attorney",
          "Management Company",
          "Pre-Litigation Paralegal",
          "General Attorney",
          "Litigation Paralegal",
          "Client Address",
          "Home Owner",
          "payAmount"
        ];
        if (this.report.type === 4) {
          keys.push("Memo");
        }
        associations.forEach(ass => {
          let found = false;
          ass.ref.forEach(r => {
            const assG = this.reports[r];
            if (searchInObject(assG, search.toLowerCase(), keys)) {
              objects.push({ ...assG, id: ass.idRef[0] });
              found = true;
              return false;
            }
          });
          if (!found) {
            rest.push(ass);
          }
        });
        result = this.removeDublicates(objects);
      }
      rest.forEach(ass => {
        let payResult = [],
          payKeys = [];
        if (this.report.type < 3) {
          payKeys = ["Payment Date", "staff", "Memo", "Payment Amount"];
          if (this.report.type === 1) {
            let p = ass.payments.slice(0, 1);
            payResult = searchInObjects(p, search.toLowerCase(), payKeys);
          } else {
            payResult = searchInObjects(
              ass.payments,
              search.toLowerCase(),
              payKeys
            );
          }
        }
        if (payResult.length > 0) {
          if (this.report.type < 3) {
            result.push(ass);
          } else {
            result.push(this.reports[ass.idRef[0]]);
          }
        } else {
          return true;
        }
      });
      if (result && result.length > 0) {
        result.sort((a, b) => {
          if (a.id > b.id) {
            return 1;
          } else if (a.id < b.id) {
            return -1;
          } else {
            return 0;
          }
        });
        this.lastSearchIndex = this.index === 0 ? 0 : this.searchResults.length;
        this.searchResults.push(...result);

        console.log(
          this.lastSearchIndex,
          this.searchResults.length - 1,
          this.getIndex(
            this.searchResults[this.lastSearchIndex],
            this.start,
            this.count
          )
        );
        const index = this.getIndex(
          this.searchResults[this.lastSearchIndex],
          this.start,
          this.count
        );
        console.log(index);
        if (index > -1) {
          this.$store.commit("reportIndex", index);
        } else {
          this.$store.commit("reportIndex", 0);
        }

        await this.mark();
      } else {
        this.start = 50 * (this.start / 50 + 1);
        if (this.start > this.associations().length) {
          this.appearToast({
            text: "No Results Found",
            timeout: 3000,
            color: "error"
          });
          this.start = 0;
          // this.$emit("input", null);
          this.searchStarted = false;
          await this.removeMark();
        } else {
          await this.searchNow();
        }
      }
      this.searchLoading = false;
      // this.$forceUpdate();
      // this.index = result[0].id;
    },
    async nextSearch() {
      await this.removeMark();
      if (
        this.report.index ===
        this.getIndex(this.searchResults[this.searchResults.length - 1])
      ) {
        this.start = 50 * (this.start / 50 + 1);
        await this.searchNow();
        console.log("will search now");
      } else {
        let last = this.lastSearchIndex,
          i = this.report.index;
        this.lastSearchIndex =
          this.searchResults.length - 1 > this.lastSearchIndex
            ? this.lastSearchIndex + 1
            : 0;
        this.$store.commit(
          "reportIndex",
          this.getIndex(this.searchResults[this.lastSearchIndex])
        );
        console.log("tranmitting");
        if (this.lastSearchIndex === last && this.index === i) {
          this.appearToast({
            text: "No More Records Found",
            timeout: 3000
          });
          await this.removeMark();
          console.log("last");
        } else {
          await this.mark();
          console.log("not last");
        }
      }
    },
    getIndex(ass) {
      if (this.report.type < 3) {
        return (
          new Array(...this.associations())
            // .splice(this.start, this.count)
            .findIndex(r => r.id === ass.id)
        );
      } else {
        // console.log(ass);
        return (
          new Array(...this.associations())
            // .splice(this.start, this.count)
            .findIndex(r => r.idRef.includes(ass.id))
        );
      }
    }
  },
  props: {
    reports: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    search: {
      immediate: true,
      handler(val) {
        this.searchOld = val;
      }
    },
    reportType() {
      console.log(this.reportType);
      this.finish();
    }
  }
};
</script>

<style scoped></style>
